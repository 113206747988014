import React, { createRef } from "react";
import { ListGroup, Button, Form, Col, Row } from "react-bootstrap";
import "../../styles/Filters.css";
import CategoryCard from "./CategoryCard";
import {
  Category,
  DefaultCategoriesGroups,
  IdToCategory,
  IdToCounty,
} from "../../schemas/Category";
import FiltersStore from "../../schemas/Filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

interface FiltersProps {
  filters: FiltersStore;
  onFilterClick: (path: string[], name: string) => void;
  onFiltersReset: () => void;
  onSearchClick: (text: string) => void;
  tabChange: (tabName: string) => void;
}

function Filters({
  filters,
  onFilterClick,
  onFiltersReset,
  onSearchClick,
  tabChange,
}: FiltersProps) {
  const searchBoxRef: any = createRef<HTMLInputElement>();
  function search() {
    onSearchClick(searchBoxRef.current.value);
    tabChange("content");
  }
  function ChangeTab() {
    tabChange("content");
  }

  return (
    <div className="filter_class">
      <ListGroup className="filter_container">
        <Form
          onSubmit={(e: { preventDefault: () => void }) => {
            e.preventDefault();
          }}
          className="ml-2 mr-2"
        >
          <Form.Row className="mt-2">
            <Button as={Col} className="ml-4 mr-2" onClick={ChangeTab}>
              Aplică filtre
            </Button>
            <Button
              as={Col}
              className="ml-2 mr-4"
              onClick={() => {
                searchBoxRef.current.value = "";
                onFiltersReset();
              }}
            >
              <FontAwesomeIcon className="mr-2" icon={faTrash} />
              Sterge filtre
            </Button>
          </Form.Row>

          <div className="input-group mt-2">
            <input
              type="text"
              className="form-control ml-4"
              placeholder="Caută după cuvinte cheie"
              ref={searchBoxRef}
              id="searchBox"
            />
            <div className="input-group-append mr-4">
              <Button onClick={search}>
                <i className="fa fa-search"></i>
              </Button>
            </div>
          </div>
        </Form>
        {Object.keys(DefaultCategoriesGroups).map((key) => {
          let cards: (JSX.Element | null)[] = [
            <ListGroup.Item key={key}>
              <CategoryCard
                name={key}
                categories={DefaultCategoriesGroups[key]}
                updateFilter={(category: Category) => {
                  onFilterClick([key], category.name);
                }}
              />
            </ListGroup.Item>,
          ];

          if (filters[key] !== undefined) {
            const subcards = Object.keys(filters[key]).map((subkeyId) => {
              const subkeyIdAsInt = parseInt(subkeyId);
              // TODO: add generic approach
              const subkey =
                key === "Categorie"
                  ? IdToCategory[subkeyIdAsInt]
                  : IdToCounty[subkeyIdAsInt];
              if (
                Object.keys(DefaultCategoriesGroups[key][subkey].subcategories!)
                  .length === 0
              )
                return null;

              return (
                <ListGroup.Item key={subkey}>
                  <CategoryCard
                    name={subkey}
                    categories={
                      DefaultCategoriesGroups[key][subkey].subcategories!
                    }
                    updateFilter={(category: Category) => {
                      onFilterClick([key, subkeyId], category.name);
                    }}
                  />
                </ListGroup.Item>
              );
            });
            cards = cards.concat(subcards);
          }

          return cards;
        })}
      </ListGroup>
    </div>
  );
}

export default Filters;
