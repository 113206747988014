import { Reducer } from "redux";
import { MarkersState } from "../../schemas/Marker";
import {
  MarkersReceiveFiltered,
  MarkersRequestFiltered,
} from "../actions/markers";

const defaultMarkersState = { isFetching: false, markers: [] };

let markersReceivedDispatcher: Function;

export function setDispatchers(markersReceived: Function) {
  markersReceivedDispatcher = markersReceived;
}

const markers: Reducer<MarkersState> = (
  state: MarkersState | undefined,
  action
) => {
  if (state === undefined) return defaultMarkersState;

  switch (action.type) {
    case "MARKERS_REQUEST_FILTERED": {
      if (state.isFetching === false) {
        const newState = { ...state, isFetching: true };
        markersReceivedDispatcher((action as MarkersRequestFiltered).filters);
        return newState;
      }
      return state;
    }

    case "MARKERS_RECEIVE_FILTERED": {
      const newState = {
        isFetching: false,
        markers: (action as MarkersReceiveFiltered).markers,
      };
      return newState;
    }

    default:
      return state;
  }
};
export default markers;
