import React from "react";
import StatefulMapContainer from "../stateful/StatefulMapContainer";
import StatefulFilters from "../stateful/StatefulFilters";
import StatefulTabbedView from "../stateful/StatefulTabbedView";

function MapView() {
  return (
    <StatefulTabbedView
      content={<StatefulMapContainer />}
      filters={<StatefulFilters />}
    />
  );
}

export default MapView;
