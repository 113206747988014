import React from "react";
import "../../styles/App.css";
import { Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export default function Pricing() {
  return (
    <div className="container mt-5 mb-5 justify-content-center" id="pachete">
      <Row xs="1" md="2" lg="3">
        <Col>
          <div className="pricingTable">
            <div className="pricingTable-header">
              <h3 className="title">Gratuit</h3>
            </div>
            <div className="pricing-content">
              <div className="price-value">
                <span className="amount">0RON</span>
              </div>
              <p className="description">
                Orice producător autorizat poate opta, gratuit pentru acest
                pachet.
              </p>
              <ul>
                <li>Apariție pe harta producătorilor</li>
                <li>Listarea producătorului</li>
                <li>Descriere scurtă</li>
                <li>Număr de telefon</li>
                <li>Website/Facebook</li>
              </ul>
            </div>
            <a href="/inregistrare" className="pricingTable-signup">
              Înscrie-te acum!
            </a>
          </div>
        </Col>
        <Col>
          <div className="pricingTable yellow mt-5">
            <div className="pricingTable-header">
              <h3 className="title">Premium</h3>
            </div>
            <div className="pricing-content">
              <div className="price-value">
                <span className="amount">40RON</span>
              </div>
              <p className="description">
                Acest pachet lunar este special pentru producătorii care își
                doresc dezvoltarea imaginii.
              </p>
              <ul>
                <li>Website de prezentare în cadrul platformei</li>
                <li>Statistici de trafic</li>
                <li>Sticker producător verificat în listare și pe hartă</li>
                <li>Include pachetul gratuit</li>
              </ul>
            </div>
            <a href="/premium" className="pricingTable-signup">
              Mai mult..
            </a>
          </div>
        </Col>
        <Col>
          <div className="pricingTable">
            <div className="pricingTable-header">
              <h3 className="title">Complet</h3>
            </div>
            <div className="pricing-content">
              <div className="price-value">
                <span className="amount">?RON</span>
              </div>
              <p className="description">
                Acest pachet conține o suită de produse.
              </p>
              <ul>
                <li>Website de prezentare/Magazin Online</li>
                <li>Optimizare SEO</li>
                <li>Logo/Banner</li>
                <li>Statistici trafic</li>
                <li>Include pachetul premium</li>
              </ul>
            </div>
            <a href="/premium" className="pricingTable-signup">
              Mai mult..
            </a>
          </div>
        </Col>
      </Row>
    </div>
  );
}
