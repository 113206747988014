import { Action, ActionCreator } from "redux";

export interface UiState {
  activeTabKey: string;
}

export interface ActiveTabAction extends Action {
  type: string;
  activeKey: string;
}

export const activeTab: ActionCreator<ActiveTabAction> = (
  activeKey: string
) => ({
  type: "ACTIVE_TAB",
  activeKey,
});
