import React from "react";
import "../../styles/App.css";
import { Container, Row, Col, Button, Badge } from "react-bootstrap";
import Navigation from "../stateless/Navigation";
import logo from "../../img/premium.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "bootstrap/dist/css/bootstrap.min.css";
import Pricing from "./Pricing";
import Footer from "./Footer";

export default function Premium() {
  return (
    <div>
      <Container className="lp-containter no-sm-padding scrollable" fluid>
        <Row className="vh-25">
          <Col>
            <Navigation />
          </Col>
        </Row>
        <Row className="mt-2 firs_row_lp" xs="1" lg="2">
          <Col>
            <div className="div_col2_lp">
              <LazyLoadImage
                src={logo}
                id="logo_premium"
                alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
              />
            </div>
          </Col>
          <Col>
            <div className="div_col1_lp">
              <h4>ZONA PREMIUM</h4>
              <h2>HARTA PRODUCĂTORILOR LOCALI ROMANI</h2>
              <p>
                Prin cumpărarea unuia dintre pachetele premium ale platformei
                noastre ne puteți ajuta să continuăm dezvoltarea platformei, și
                totodata vă puteți da un boost în ceea ce privește imaginea
                firmei și promovarea către consumatori (persoane fizice și
                juridice). Pentru a comanda unul dintre pachetele premium, ne
                puteti contacta la adresa <b>andrei@utilsoftwaresolutions.ro</b>{" "}
                sau pe{" "}
                <a href="https://www.facebook.com/hartaproducatorilromani/">
                  Facebook
                </a>
                .
              </p>
              <div className="first_row_buttons">
                <a href="/harta">
                  <Button color="primary" size="lg" active>
                    Spre Harta
                  </Button>
                </a>
                <a href="https://tawk.to/chat/5e9ed12e35bcbb0c9ab336b1/1e73gdm3b">
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="button_inscriere"
                  >
                    Mai multe detalii
                  </Button>
                </a>
              </div>
            </div>
          </Col>
        </Row>
        <Pricing />
        <Row className="mt-2 firs_row_lp" xs="1" lg="2">
          <Col>
            <div className="div_col1_lp">
              <h4>PREMIUM</h4>
              <h2>PACHET PREMIUM</h2>
              <p>
                Acest pachet lunar este special pentru producătorii care își
                doresc dezvoltarea imaginii. Prin intermediul acestui pachet,
                producatorii vor fi mai vizibili, si in acelasi timp vor avea si
                o zona dedicata lor pe website, unde isi pot spune pe larg
                povestea, informatii suplimentare, o lista cu ce pot produce si
                multe alte detalii . Pretul pachetului este de{" "}
                <b>30 lei/luna</b> si include urmatoarele beneficii:
              </p>
              <ul>
                <li>Aparitie pe harta producatorilor.</li>
                <li>
                  Crearea unui website de prezentare, pe larg, in cadrul
                  platformei.
                </li>
                <li>Statistici de trafic.</li>
                <li>
                  Sticker producător verificat în listare și pe hartă.
                  <Badge variant="success" className="testt">
                    Verificat
                  </Badge>
                </li>
                <li>Aparitie in zona de producatori promovati.</li>
              </ul>
              <div className="first_row_buttons">
                <a href="/producatori/dealbine">
                  <Button color="primary" size="lg" active>
                    Exemplu de website
                  </Button>
                </a>
                <a href="https://tawk.to/chat/5e9ed12e35bcbb0c9ab336b1/1e73gdm3b">
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="button_inscriere"
                  >
                    Comanda
                  </Button>
                </a>
              </div>
            </div>
          </Col>
          <Col>
            <div className="div_col1_lp">
              <h4>PREMIUM</h4>
              <h2>PACHET COMPLET</h2>
              <p>
                Acest pachet se adresează producătorilor care vor să își
                dezvolte afacerea în mediul online și înafara platformei. Fiind
                un pachet mai complex, acesta poate conține realizarea unui
                website de prezentare sau al unui magazin online, optimizare SEO
                (pentru sistemele de cautare), dezvoltarea imaginii (logo,
                banner), si includerea pachetului PREMIUM.
              </p>
              <p>
                Pretul acestui pachet variaza in functie de ce servicii doreste
                producatorul, iar plata se poate face lunar, anual sau o singura
                data.
              </p>
              <div className="first_row_buttons">
                <a href="https://www.facebook.com/uhsoftwaresolutions/">
                  <Button color="primary" size="lg" active>
                    Exemple de website
                  </Button>
                </a>
                <a href="https://tawk.to/chat/5e9ed12e35bcbb0c9ab336b1/1e73gdm3b">
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="button_inscriere"
                  >
                    Comanda
                  </Button>
                </a>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-2 second_row_lp" xs="1" lg="2">
          <Col>
            <div className="div_col1_lp">
              <h4>PREMIUM</h4>
              <h2>SPRIJIN RECIPROC, ECOSISTEM</h2>
              <p>
                Proiectul nostru este unul de anvergura, prin care noi incercam
                sa punem in valoare calitatile de anteprenoriat si de
                digitalizare a afacerilor, ajutand la crearea unui ecosistem
                format din producatori si consumatori, pentru a incerca rularea
                a cator mai multe produse Romanesti, in tara noastra. Pentru a
                comanda unul dintre pachetele premium, ne puteti contacta la
                adresa <b>andrei@utilsoftwaresolutions.ro</b> sau pe{" "}
                <a href="https://www.facebook.com/hartaproducatorilromani/">
                  Facebook
                </a>
                .
              </p>
              <div className="first_row_buttons">
                <a href="/listing">
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="button_inscriere"
                  >
                    Listare
                  </Button>
                </a>
                <a href="/harta">
                  <Button color="primary" size="lg" active>
                    Harta
                  </Button>
                </a>
              </div>
            </div>
          </Col>
          <Col>
            <div className="div_col2_lp">
              <LazyLoadImage
                src={logo}
                id="logo_premium"
                alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}
