import { combineReducers, Reducer } from "redux";
import filters from "./filters";
import markers from "./markers";
import ui from "./ui";
import FiltersStore from "../../schemas/Filters";
import { MarkersState } from "../../schemas/Marker";
import { UiState } from "../actions/ui";

export interface ApplicationState {
  filters: FiltersStore;
  markers: MarkersState;
  ui: UiState;
}

const rootReducer: Reducer<ApplicationState> = combineReducers({
  filters,
  markers,
  ui,
});
export default rootReducer;
