import { stateToUiProps, dispatchToUiProps } from "../../state/dispatchers/ui";
import { connect } from "react-redux";
import TabbedView from "../stateless/TabbedView";

const StatefulTabbedView = connect(
  stateToUiProps,
  dispatchToUiProps
)(TabbedView);

export default StatefulTabbedView;
