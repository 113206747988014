import React from "react";
import StatefulFilters from "../stateful/StatefulFilters";
import StatefulListing from "../stateful/StatefulListing";
import StatefulTabbedView from "../stateful/StatefulTabbedView";

function MapView() {
  return (
    <StatefulTabbedView
      content={<StatefulListing />}
      filters={<StatefulFilters />}
    />
  );
}

export default MapView;
