import { FilterAlterAction, FilterTextAction } from "../actions/filters";
import FiltersStore from "../../schemas/Filters";
import { Reducer } from "redux";
import { CategoryToId, CountyToId } from "../../schemas/Category";
import cloneDeep from "lodash.clonedeep";

function updateFilterInternal(
  filters: FiltersStore,
  { name, path }: FilterAlterAction
) {
  // TODO: add generic approach
  const id: number =
    path[0] === "Categorie"
      ? CategoryToId[name].toString()
      : CountyToId[name].toString();
  const newFilters: FiltersStore = cloneDeep(filters);

  let filtersIter: FiltersStore = newFilters;
  let filtersIterParent: FiltersStore | undefined;
  for (const pathToken of path) {
    if (filtersIter[pathToken] === true) filtersIter[pathToken] = {};
    filtersIterParent = filtersIter;
    filtersIter = filtersIter[pathToken] as FiltersStore;
  }

  if (filtersIter[id] === undefined) {
    filtersIter[id] = true;
  } else {
    delete filtersIter[id];
    if (Object.keys(filtersIter).length === 0)
      filtersIterParent![path[path.length - 1]] = true;
  }

  return newFilters;
}

export const defaultFiltersState: FiltersStore = {
  Categorie: {},
  Judet: {},
};

const filters: Reducer<FiltersStore> = (
  state: FiltersStore | undefined,
  action
) => {
  if (state === undefined) {
    return filters(defaultFiltersState, action);
  }

  switch (action.type) {
    case "FILTER_ALTER": {
      return updateFilterInternal(state, action as FilterAlterAction);
    }
    case "FILTERS_RESET": {
      return defaultFiltersState;
    }
    case "FILTER_TEXT": {
      const newState = { ...state, text: (action as FilterTextAction).text };
      return newState;
    }
    default:
      return state;
  }
};
export default filters;
