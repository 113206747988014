import { ApplicationState } from "../reducers";
import { filterAlter, filtersReset, filterText } from "../actions/filters";
import store from "../index";
import { markersRequestFiltered } from "../actions/markers";

export const stateToFiltersProps = (state: ApplicationState) => {
  return {
    filters: state.filters,
  };
};

// TODO: find a nicer way to do this
function uncheckAll() {
  const checkboxes = document.querySelectorAll('input[type="checkbox"]');
  checkboxes.forEach((checkbox) => {
    (checkbox as any).checked = false;
  });
}

export function dispatchFiltersReset(dispatch?: Function | undefined) {
  if (dispatch === undefined) dispatch = store.dispatch;
  else uncheckAll();
  dispatch(filtersReset());
  dispatch(markersRequestFiltered(store.getState().filters));
}

export const dispatchToFiltersProps = (dispatch: Function) => {
  return {
    onFilterClick: (path: string[], name: string) => {
      dispatch(filterAlter(path, name));
      dispatch(markersRequestFiltered(store.getState().filters));
    },

    onFiltersReset: () => {
      dispatchFiltersReset(dispatch);
    },

    onSearchClick: (text: string) => {
      dispatch(filterText(text));
      dispatch(markersRequestFiltered(store.getState().filters));
    },
  };
};
