import { Action, ActionCreator } from "redux";

export interface FilterAlterAction extends Action {
  type: string;
  path: string[];
  name: string;
}

export interface FiltersResetAction extends Action {
  type: string;
}

export const filterAlter: ActionCreator<FilterAlterAction> = (
  path: string[],
  name: string
) => ({
  type: "FILTER_ALTER",
  path,
  name,
});

export const filtersReset: ActionCreator<FiltersResetAction> = () => ({
  type: "FILTERS_RESET",
});

export interface FilterTextAction extends Action {
  type: string;
  text: string;
}

export const filterText: ActionCreator<FilterTextAction> = (text: string) => ({
  type: "FILTER_TEXT",
  text,
});
