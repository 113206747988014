import React from "react";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import StatefulFilters from "../stateful/StatefulFilters";
import Navigation from "./Navigation";
import { UiState } from "../../state/actions/ui";

interface TabbedViewProperties {
  content: JSX.Element;
  filters: JSX.Element;
  tabChange: (tabName: string) => void;
  ui: UiState;
}

function TabbedView({ content, filters, tabChange, ui }: TabbedViewProperties) {
  return (
    <Container className="vh-100 main-container no-sm-padding" fluid>
      <Row>
        <Col>
          <Navigation />
        </Col>
      </Row>
      <Row className="margin_top harta">
        <Col xs="3" className="web_col">
          <StatefulFilters />
        </Col>
        <Col>
          <Tabs
            activeKey={ui.activeTabKey}
            id="default-tabs"
            onSelect={(newActiveKey: string | null) => {
              tabChange(newActiveKey as string);
            }}
          >
            <Tab
              eventKey="content"
              title="Listare"
              tabClassName="harta_tab_mobile"
            >
              {content}
            </Tab>
            <Tab
              eventKey="filters"
              title="Filtre"
              tabClassName="filter_tab_mobile"
            >
              {filters}
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
}

export default TabbedView;
