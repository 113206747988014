import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "../../styles/App.css";
import { Row, Col } from "react-bootstrap";
import "react-alice-carousel/lib/alice-carousel.css";

export default function MediaLogoSliders() {
  return (
    <div>
      <Row className="mt-2 fourth_row_lp" xs="3" lg="5" md="2">
        <Col>
          <div>
            <a href="https://www.startupcafe.ro/idei-antreprenori/harta-producatori-romania-miere-agroalimentare-hand-made.htm">
              <LazyLoadImage
                src="https://www.startupcafe.ro/themes/custom/startupcafetheme/images/logo.png"
                alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
              />
            </a>
          </div>
        </Col>
        <Col>
          <div>
            <a href="https://www.ziaruldevrancea.ro/actualitatea/stiri-locale/doi-tineri-din-adjud-au-realizat-harta-producatorilor-locali-romani">
              <LazyLoadImage
                src="https://scontent.fias1-1.fna.fbcdn.net/v/t1.0-9/p960x960/83310463_2928504430513108_1784845759468797952_o.png?_nc_cat=111&_nc_sid=85a577&_nc_ohc=Yxm_MmDOW2sAX_Qo-Ol&_nc_ht=scontent.fias1-1.fna&oh=0597eaad32338fec1b71ee8cab353558&oe=5EC4B074"
                alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
              />
            </a>
          </div>
        </Col>
        <Col>
          <div>
            <a href="https://monitoruldevrancea.ro/2020/04/14/doi-studenti-din-adjud-au-creat-o-platforma-online-pentru-producatorii-locali-din-romania/">
              <LazyLoadImage
                src="https://pbs.twimg.com/profile_images/1021705907984588801/zJtNPDv8.jpg"
                alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
              />
            </a>
          </div>
        </Col>
        <Col>
          <div>
            <a href="https://economie.hotnews.ro/stiri-consumator-23846076-harta-producatorilor-locali-din-romania-fii-gasit-mai-usor-clienti.htm">
              <LazyLoadImage
                src="https://www.cid.org.ro/wp-content/uploads/HotNews-logo.jpg"
                alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
              />
            </a>
          </div>
        </Col>
        <Col>
          <div>
            <a href="https://stiri.tvr.ro/consumatorii-de-produse-autohtone--la-un-click-distan--a-de-micii-antreprenori-locali_859268.html#view">
              <LazyLoadImage
                alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
                src="https://pbs.twimg.com/profile_images/722054128743145472/vC5Z_BPh_400x400.jpg"
              />
            </a>
          </div>
        </Col>
      </Row>
      <Row className="mt-2 fourth_row_lp" xs="3" lg="5" md="2">
        <Col>
          <div>
            <a href="#">
              <LazyLoadImage
                src="https://www.mediafax.ro/images/mediafax.jpg"
                alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
              />
            </a>
          </div>
        </Col>
        <Col>
          <div>
            <a href="https://repatriot.ro/harta-producatorilor-locali-din-romania/">
              <LazyLoadImage
                src="https://www.rbls.ro/wp-content/uploads/2017/01/repatriot_400x87.jpg"
                alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
              />
            </a>
          </div>
        </Col>
        <Col>
          <div>
            <a href="https://www.youtube.com/watch?v=6s1xGiz8Lts">
              <LazyLoadImage
                src="https://www.radardemedia.ro/wp-content/uploads/2017/02/TVR-2-LOGO.png"
                alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
              />
            </a>
          </div>
        </Col>
        <Col>
          <div>
            <a href="#">
              <LazyLoadImage
                src="https://www.rri.ro/newfiles/images/logo-rri-fundalalb-640-4-.png"
                alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
              />
            </a>
          </div>
        </Col>
        <Col>
          <div>
            <a href="https://jurnalul.antena3.ro/bani-afaceri/platforme-online-pentru-producatorii-agricoli-si-liceeni-842790.html">
              <LazyLoadImage
                alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
                src="https://jurnalul.antena3.ro/media/logo%20jurnalul%20nou(1).jpg"
              />
            </a>
          </div>
        </Col>
      </Row>
    </div>
  );
}
