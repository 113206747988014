import { ListGroup, Button } from "react-bootstrap";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Marker as StoredMarker } from "../../schemas/Marker";
import { categoriesIconsList, IdToCategory } from "../../schemas/Category";
import pmontan from "../../img/pmontan.png";

interface StatefulListItemProperties {
  marker: StoredMarker;
}

function StatefulListItem({ marker }: StatefulListItemProperties) {
  // sanitize
  if (marker?.subcategoryId === undefined) {
    return null;
  }

  if (
    IdToCategory[marker?.subcategoryId.toString()] === undefined ||
    categoriesIconsList[IdToCategory[marker?.subcategoryId.toString()]] ===
      undefined
  ) {
    return null;
  }

  return (
    <ListGroup.Item action variant="info" className="listing_option">
      <LazyLoadImage
        src={
          categoriesIconsList[IdToCategory[marker?.subcategoryId.toString()]]
            .urlListing
        }
        alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
        className="rounded_image"
      />
      <h6>{"Categorie: " + IdToCategory[marker?.categoryId.toString()]}</h6>
      <p>
        {"Sub-Categorie: " + IdToCategory[marker?.subcategoryId.toString()]}
      </p>

      <h5>{marker.title}</h5>

      <p className="marker_description">{marker.description}</p>
      {marker.montan ? (
        <LazyLoadImage
          src={pmontan}
          alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
          className="rounded_image"
        />
      ) : null}
      {marker.phone !== "Indisponibil" ? (
        window.innerWidth < 1000 ? (
          <Button className="mb-2" as="a" href={"tel:" + marker.phone}>
            Suna acum!
          </Button>
        ) : (
          <Button
            className="mb-2"
            as="a"
            href={"tel:" + marker.phone}
            onClick={() => {
              const el = document.createElement("textarea");
              el.value = marker.phone;
              document.body.appendChild(el);
              el.select();
              document.execCommand("copy");
              document.body.removeChild(el);
            }}
          >
            {marker.phone}
          </Button>
        )
      ) : null}
      <Button
        className="mb-2 ml-2"
        as="a"
        href={
          "https://www.google.com/maps?saddr=My+Location&daddr=" +
          marker.position.latitude +
          "," +
          marker.position.longitude
        }
      >
        <i className="fa fa-map-marker" aria-hidden="true">
          {" "}
          Spre locație
        </i>
      </Button>

      {marker.verified ? (
        <Button
          className="ml-2 mb-2"
          as="a"
          href={"/producatori/" + marker.customRoute}
          target="_blank"
        >
          Profil producător
        </Button>
      ) : marker.website !== undefined && // TODO: sanitize in the backend
        marker.website !== null &&
        marker.website !== "" &&
        marker.website[0] !== "#" ? (
        marker.website.includes("@") ? (
          <Button
            className="ml-2 mb-2"
            as="a"
            href={"mailto:" + marker.website}
            target="_blank"
          >
            Email
          </Button>
        ) : (
          <Button
            className="ml-2 mb-2"
            as="a"
            href={marker.website}
            target="_blank"
          >
            Website
          </Button>
        )
      ) : null}
    </ListGroup.Item>
  );
}

export default StatefulListItem;
