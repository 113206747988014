import { Marker } from "./schemas/Marker";
import FiltersStore from "./schemas/Filters";

const backendUrl = process.env.REACT_APP_API_URL;

export async function registerMarker(newMarker: any) {
  await fetch(`${backendUrl}/register`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newMarker),
  });
}

async function fetchMarkers(filters: FiltersStore) {
  const resp = await fetch(`${backendUrl}/filter`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(filters),
  });
  const fetchedMarkers: Marker[] = await resp.json();
  return fetchedMarkers;
}

export async function requestFullMarker(id: string) {
  const resp = await fetch(`${backendUrl}/${id}`);
  const fetchedMarker: Marker = await resp.json();
  return fetchedMarker;
}

export async function requestFullMarkerByRoute(route: string) {
  const resp = await fetch(`${backendUrl}/custom/${route}`);
  const fetchedMarker: Marker = await resp.json();
  return fetchedMarker;
}

export async function requestFullMarkersPaginated(
  filters: FiltersStore,
  itemsPerPage: number,
  page: number,
  verified = false
) {
  const query = {
    filtersTree: filters,
    itemsPerPage,
    page,
  } as any;
  if (verified) {
    query.verified = true;
  }

  const resp = await fetch(`${backendUrl}/filter/page`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(query),
  });
  const fetchedMarkers: Marker[] = await resp.json();
  return fetchedMarkers;
}

export async function getPagesCount(
  filters: FiltersStore,
  itemsPerPage: number
) {
  const resp = await fetch(`${backendUrl}/pages`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      filtersTree: filters,
      itemsPerPage,
    }),
  });
  const count: number = (await resp.json()).count;
  return count;
}

export async function requestFilteredMarkers(
  dispatch: Function,
  filters: FiltersStore
) {
  const markers = await fetchMarkers(filters);
  dispatch(markers);
}
