import React from "react";
import { Marker as StoredMarker } from "../../schemas/Marker";
import ListGroup from "react-bootstrap/ListGroup";
import StatefulListItem from "../stateful/StatefulListItem";
import store from "../../state";

interface ListingProperities {
  markers: StoredMarker[];
}

function Listing(properties: ListingProperities) {
  const { markers } = properties;

  return (
    <div className="listing_container">
      <ListGroup>
        {markers.map((marker, index) => (
          <StatefulListItem key={index} marker={marker} />
        ))}
        {(markers.length === 0 &&
          Object.keys(store.getState().filters.Categorie).length !== 0) ||
        Object.keys(store.getState().filters.Judet).length !== 0 ? (
          <ListGroup.Item action variant="danger" className="listing_option">
            Nu sunt producători conform filtrelor dvs.
          </ListGroup.Item>
        ) : null}
      </ListGroup>
    </div>
  );
}

export default Listing;
