export interface Category {
  name: string;
  filterField: string;
  icon?: string;
  subcategories?: CategoriesMap;
}

export interface CategoriesMap {
  [key: string]: Category;
}

export interface CategoriesGroup {
  [key: string]: CategoriesMap;
}

export interface CategoryIcon {
  urlListing: string;
  urlMap: string;
}

export interface CategoriesIconsList {
  [key: string]: CategoryIcon;
}

export const categoriesIconsList: CategoriesIconsList = require("../dataset/icons.json");

export const categoriesMap: CategoriesMap = require("../dataset/categories.json");
export const countyMap: CategoriesMap = require("../dataset/counties.json");

export const DefaultCategoriesGroups = {
  Categorie: categoriesMap,
  Judet: countyMap,
} as CategoriesGroup;

export const DefaultCategoriesFilter = {
  Categorie: {},
  Judet: {},
};

export const CategoryToId = require("../dataset/categoriesIds.json");
export const IdToCategory = require("../dataset/idToCategory.json");
export const CountyToId = require("../dataset/countyToId.json");
export const IdToCounty = require("../dataset/idToCounty.json");
