import React from "react";
import "../../styles/App.css";

export default function TermeniConditii() {
  return (
    <div className="termeni">
      <h3>TERMENI SI CONDITII GDPR</h3>
      <br></br>Conditii Generale pentru utilizarea site-ului Harta
      Producatorilor Locali Romani (producatoridinromania.ro)
      <br></br>
      <b>ACCEPTAREA CONDITIILOR</b>
      <br></br>Prin accesarea acestui site web si/sau a oricarei pagini a
      acestuia sunteti de acord cu aceste conditii de utilizare. Daca nu sunteti
      de acord cu acesti termeni si conditii de utilizare, nu accesati acest
      site.
      <br></br>
      <b>DESCRIEREA SERVICIILOR</b>
      <br></br>Siteul nostru va pune la dispozitie informatiile din acest site
      web in scop informativ general si nu garanteaza de exactitatea lor la un
      moment dat, desi se va incerca pe cat posibil ca la publicarea lor pe site
      toate informatiile sa fie exacte.
      <br></br>
      <b>INTRODUCERE</b>
      <br></br>Prezentele Conditii Generale definesc conditiile de utilizare a
      site-ului Harta Producatorilor Locali Romani (producatoridinromania.ro) de
      catre potentialii vizitatori sau clienti. Accesând si navigând pe acest
      site, acceptati termenii de utilizare descrisi în continuare.
      <br></br>
      <b>DREPTURILE ASUPRA CONTINUTULUI SITE-ULUI</b>
      <br></br>UtilHelp Software&Solutions (denumita în continuare UtilHelp
      Software&Solutions), nu va transfera titlul de proprietate asupra
      aplicatiilor software.
      <br></br>UtilHelp Software&Solutions detine drepturi complete si depline
      asupra titlului de proprietate si prin aceasta toate drepturile de autor
      si cele brevetate. Nu aveti dreptul sa redistribuiti, vindeti,
      decompilati, dezasamblati aplicatia software într-o forma perceptibila de
      catre oameni.
      <br></br>Toate informatiile, produsele sau aplicatiile continute în acest
      site sunt proprietatea UtilHelp Software&Solutions, care îsi rezerva
      dreptul de a modifica continutul si/sau structura site-ului în orice
      moment si fara nici o informare prealabila.
      <br></br>Întregul continut al site-ului Harta Producatorilor Locali Romani
      (producatoridinromania.ro) este protejat prin legea drepturilor de autor,
      toate drepturile fiind rezervate. Toate drepturile referitoare la pagini,
      continutul si prezentarea site-ului sunt detinute de UtilHelp
      Software&Solutions. Este interzisa copierea, modificarea, afisarea,
      distribuirea, transmiterea, publicarea, comercializarea, licentierea,
      crearea unor materiale derivate sau utilizarea continutului site-ului în
      orice scop fara confirmarea scrisa din partea UtilHelp Software&Solutions.
      <br></br>Accesul si utilizarea paginii Harta Producatorilor Locali Romani
      (producatoridinromania.ro) sunt gratuite si au scopul de a veni in
      ajutorul utilizatorilor pentru a gasi informatiile necesare în cel mai
      usor si rapid mod posibil, conform cerintelor fiecaruia.
      <br></br>Informatiile prezente pe Harta Producatorilor Locali Romani
      (producatoridinromania.ro) sunt de interes general si sunt puse la
      dispozitia utilizatorilor în mod gratuit. Prin termenul „utilizator” al
      acestei pagini se întelege orice persoana fizica sau juridica care va
      accesa pagina. Puteti copia si tipari continutul paginii Harta
      Producatorilor Locali Romani (producatoridinromania.ro) pentru folosinta
      dumneavoastra personala, fara intentii comerciale. În orice alte situatii,
      continutul Harta Producatorilor Locali Romani (producatoridinromania.ro)
      nu poate fi reprodus, modificat sau exploatat fara consimtamântul explicit
      al reprezentantilor Harta Producatorilor Locali Romani
      (producatoridinromania.ro).
      <br></br>
      <b>NEANGAJAREA RASPUNDERII</b>
      <br></br>Continutul informatiilor se refera la descrierea într-un anumit
      grad de detaliere a activitatilor, produselor si serviciilor UtilHelp
      Software&Solutions. UtilHelp Software&Solutions nu va acorda nici o
      garantie referitoare la:
      <br></br>– evitarea utilizarii anevoioase sau întreruperii în utilizare a
      site-ului;
      <br></br>– neafectarea în sens negativ a altor sisteme prin utilizarea
      site-ului;
      <br></br>– inexistenta pe site-ul pus la dispozitie a virusilor sau alte
      componente care ar putea dauna utilizatorilor.
      <br></br>Astfel, UtilHelp Software&Solutions nu poate fi responsabila
      pentru nici un fel de daune directe sau indirecte produse prin utilizarea
      site-ului sau.
      <br></br>Toate informatiile prezentate pe site cu privire la produsele
      Harta Producatorilor Locali Romani (producatoridinromania.ro), preturi,
      informatii, campanii si promotii de marketing, aspecte tehnice, sunt
      prezentate cu titlu informativ. Nimic din continutul site-ului Harta
      Producatorilor Locali Romani (producatoridinromania.ro) nu poate constitui
      o oferta ferma de a contracta si nu poate angaja raspunderea Amigio în
      lipsa unor acorduri ulterioare.
      <br></br>Continutul paginii web Harta Producatorilor Locali Romani
      (producatoridinromania.ro), a informatiilor si a altor materiale
      prezentate de Comunitatea Harta Producatorilor Locali Romani
      (producatoridinromania.ro) nu reprezinta o consultatie juridica în sensul
      Legii nr. 51/1995.
      <br></br>GDPR este un set de reguli bazat pe principii complexe, care este
      deschis la interpretare si cotine, de asemenea, numeroase domenii în care
      statele membre sunt invitate sa intervina. În prezent exista foarte putine
      îndrumari cu privire la implementarea GDPR si este posibil ca Autoritatile
      de Supraveghere sa aiba puncte de vedere diferite fata de cele exprimate
      în acest ?ablon. În ciuda eforturilor noastre de a oferi cele mai bune
      servicii, nu putem anticipa cu exactitate modul în care Autoritatea de
      Supraveghere sau instan?ele de judecata vor interpreta Regulamentul.
      <br></br>Harta Producatorilor Locali Romani (producatoridinromania.ro) nu
      face nicio declaratie, promisiune sau garantie cu privire la exactitatea,
      exhaustivitatea sau caracterul adecvat al prezentului continut, al
      îndrumarilor si a consultantei, nu îsi asuma nicio obligatie de rezultat
      sau diligenta fata de nicio persoana, exclud în mod expres si neaga
      raspunderea pentru orice cost, pierdere sau daunele suferite ca urmare a
      utilizarii continutului.
      <br></br>
      <b>OBIECTIVUL CONTINUTULUI SITE-ULUI</b>
      <br></br>Obiectivul continutului site-ului este de a transmite informatii
      actualizate si exacte.
      <br></br>UtilHelp Software&Solutions nu poate garanta ca prezentele pagini
      nu contin erori, si asigura ca va depune toate diligentele pentru
      realizarea unei informari corecte si remedierea eventualelor erori.
      <br></br>Orice persoana care doreste sa-si procure unul din serviciile sau
      produsele prezentate în site este rugata sa contacteze UtilHelp
      Software&Solutions prin unul din mijloacele afisate în pagina de Contact a
      site-ului, pentru a se informa atât asupra disponibilitatii serviciului
      sau produsului în cauza cât si asupra conditiilor contractuale, tarifelor
      si informatiilor tehnice sau de alta natura.
      <br></br>
      <b>INFORMATII PERSONALE</b>
      <br></br>Când prin intermediul prezentului site va sunt solicitate
      informatii, aceasta are ca scop identificarea dumneavoastra sau
      posibilitatea de a va contacta. Acest lucru este cu atât mai necesar în
      cazul folosirii aplicatiilor disponibile pe site.
      <br></br>Natura informatiilor solicitate se refera în special la date
      personale (nume, adresa, numere de telefon), adresa de e-mail, modul în
      care sunt utilizate sau intentioneaza sa fie utilizate produsele si/sau
      serviciile UtilHelp Software&Solutions, dar poate include si alte
      informatii aflate în strânsa legatura cu utilizarea serviciilor si/sau
      produselor solicitate.
      <br></br>Cu scopul de a raspunde mai bine nevoilor si întrebarilor
      utilizatorilor site-ului, informatiile solicitate prin intermediul acestui
      site vor face obiectul unei stocari si prelucrari electronice.
      <br></br>
      <b>LEGATURA CU ALTE SITE-URI</b>
      <br></br>Prezentul site poate contine legaturi sau trimiteri catre alte
      site-uri considerate de UtilHelp Software&Solutions utile în legatura cu
      continutul site-ului sau si care nu se afla sub controlul sau îndrumarea
      sa. În cazul utilizarii acestor legaturi sau trimiteri se vor aplica
      conditiile generale de utilizare corespunzatoare acelor site-uri.
      <br></br>UtilHelp Software&Solutions nu poate garanta/controla
      actualitatea/exactitatea informatiilor prezente pe site-urile unor terti,
      la care se face trimitere de pe site-ul sau.
      <br></br>
      <b>INFORMATIILE OFERITE PRIN INTERMEDIUL SITE-ULUI</b>
      <br></br>Orice persoana care viziteaza site-ul Harta Producatorilor Locali
      Romani (producatoridinromania.ro) si care ofera date sau informatii cu
      caracter personal prin intermediul acestui site îsi manifesta acordul în
      mod expres si neechivoc pentru urmatoarele: prelucrarea acestor date si
      informatii personale de catre UtilHelp Software&Solutions; transmiterii de
      materiale promotionale specifice operatiunilor de marketing direct;
      solutionarea de catre UtilHelp Software&Solutions a cererilor,
      întrebarilor si reclamatiilor adresate (a se vedea pagina Contact din
      site); alte activitati întreprinse de UtilHelp Software&Solutions si
      permise de lege, ce nu fac obiectul unei aprobari din partea
      destinatarului.
      <br></br>UtilHelp Software&Solutions va pastra confidentialitatea acestor
      informatii.
      <br></br>Utilizarea în continuare a acestui site constituie acordul expres
      si neechivoc al dumneavoastra în conformitate cu prevederile legii
      677/2001 si directivelor GDPR pentru protectia persoanelor cu privire la
      prelucrarea datelor cu caracter personal si libera circulatie a acestor
      date.
      <br></br>Pentru orice nelamurire în legatura cu exercitarea drepturilor
      dumneavoastra referitoare la utilizarea site-ului si la protectia
      utilizarii va rugam sa ne contactati prin intermediul sectiunii Contact
      din site.
    </div>
  );
}
