import { activeTab } from "../actions/ui";
import { ApplicationState } from "../reducers";

export const stateToUiProps = (state: ApplicationState) => {
  return {
    ui: state.ui,
  };
};

export const dispatchToUiProps = (dispatch: Function) => {
  return {
    tabChange: (tabName: string) => {
      dispatch(activeTab(tabName));
    },
  };
};
