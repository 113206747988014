import { Reducer } from "redux";
import { UiState, ActiveTabAction } from "../actions/ui";

const defaultUiState: UiState = {
  activeTabKey: "content",
};

const ui: Reducer<UiState> = (state: UiState | undefined, action) => {
  if (state === undefined) {
    return defaultUiState;
  }

  switch (action.type) {
    case "ACTIVE_TAB": {
      return { ...state, activeTabKey: (action as ActiveTabAction).activeKey };
    }

    default:
      return state;
  }
};

export default ui;
