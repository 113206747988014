import {
  stateToFiltersProps,
  dispatchToFiltersProps,
} from "../../state/dispatchers/filters";
import { dispatchToUiProps, stateToUiProps } from "../../state/dispatchers/ui";
import { connect } from "react-redux";
import Filters from "../stateless/Filters";

const StatefulFiltersInitial = connect(
  stateToFiltersProps,
  dispatchToFiltersProps
)(Filters);
const StatefulFiltersWithTabProps = connect(
  stateToUiProps,
  dispatchToUiProps
)(StatefulFiltersInitial);
const StatefulFilters = StatefulFiltersWithTabProps;

export default StatefulFilters;
