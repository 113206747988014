import { Action, ActionCreator } from "redux";
import FiltersStore from "../../schemas/Filters";
import { MarkersStore } from "../../schemas/Marker";

export interface MarkersRequestFiltered extends Action {
  type: string;
  filters: FiltersStore;
}

export const markersRequestFiltered: ActionCreator<MarkersRequestFiltered> = (
  filters: FiltersStore
) => ({
  type: "MARKERS_REQUEST_FILTERED",
  filters,
});

export interface MarkersReceiveFiltered extends Action {
  type: string;
  markers: MarkersStore;
}

export const markersReceiveFiltered: ActionCreator<MarkersReceiveFiltered> = (
  markers: MarkersStore
) => ({
  type: "MARKERS_RECEIVE_FILTERED",
  markers,
});
