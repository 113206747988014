import React from "react";
import "../../styles/App.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import StatefulMapContainer from "../stateful/StatefulMapContainer";
import Navigation from "../stateless/Navigation";
import logo from "../../img/logo_transparent.png";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "bootstrap/dist/css/bootstrap.min.css";
import MediaLogoSliders from "./MediaLogoSliders";
import Pricing from "./Pricing";

export default function Footer() {
  return (
    <footer id="myFooter">
      <div className="container">
        <div className="row">
          <div className="col-sm-3">
            <a href="/">
              <LazyLoadImage
                src={logo}
                alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
              />
            </a>
          </div>
          <div className="col-sm-2">
            <h5>Link-uri utile</h5>
            <ul>
              <li>
                <a href="/termeni">Termeni și condiții</a>
              </li>
              <li>
                <a href="#">Politici de confidențialitate</a>
              </li>
              <li>
                <a href="/premium">Susține-ne!</a>
              </li>
            </ul>
          </div>
          <div className="col-sm-2">
            <h5>Tool-uri</h5>
            <ul>
              <li>
                <a href="/harta">Harta Producătorilor</a>
              </li>
              <li>
                <a href="/listing">Listarea Producătorilor</a>
              </li>
              <li>
                <a href="/premium">Pachete pentru producători</a>
              </li>
            </ul>
          </div>
          <div className="col-sm-3">
            <h5>Contact</h5>
            <p>
              Pentru orice fel de problemă/bug/sugestie/propunere/susținere nu
              ezitați să ne contactați pe{" "}
              <a href="https://www.facebook.com/hartaproducatorilromani">
                Facebook
              </a>{" "}
              sau pe email{" "}
              <a href="mailto:andrei@utilsoftwaresolutions.ro">
                andrei@utilsoftwaresolutions.ro
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <p>
          © 2020 producatoridinromania.ro - Harta Producatorilor, Lista
          Producatorilor{" "}
        </p>
      </div>
    </footer>
  );
}
