import React from "react";
import { Navbar, Button, Form, Col } from "react-bootstrap";
import logo from "../../img/logo_transparent.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

const scrollTop = () => {
  window.scrollTo(0, 0);
};

function Navigation() {
  return (
    <div className="nav-container">
      <Navbar className="navbar-style navbar-expand-md fixed-top" expand="lg">
        <button
          type="button"
          className="navbar-toggler"
          data-toggle="collapse"
          data-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <a href="/" onClick={scrollTop}>
          <Navbar.Brand className="big-logo">
            <LazyLoadImage
              src={logo}
              className="logo"
              alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
            />
          </Navbar.Brand>
        </a>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ml-auto">
            <a href="/" className="nav-item nav-link" onClick={scrollTop}>
              Acasă
            </a>
            <a href="/harta" className="nav-item nav-link">
              Hartă
            </a>
            <a href="/listing" className="nav-item nav-link">
              Listare
            </a>
            <a href="/premium" className="nav-item nav-link">
              Pachete
            </a>
            <a href="/#parteneri" className="nav-item nav-link">
              Parteneri
            </a>
            <a href="/inregistrare" className="nav-item nav-link nav_inscriere">
              Inscriere producatori
            </a>
          </div>
        </div>
      </Navbar>
    </div>
  );
}

export default Navigation;
