import React, { useState } from "react";
import { Marker as StoredMarker } from "../../schemas/Marker";
import { Marker, Popup } from "react-leaflet";
import { Button } from "react-bootstrap";
import { categoriesIconsList, IdToCategory } from "../../schemas/Category";
import L from "leaflet";
import { requestFullMarker } from "../../Database";
import { Link } from "react-router-dom";
import pmontan from "../../img/pmontan.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
interface StatefulMarkerProperties {
  marker: StoredMarker;
}

function StatefulMarker({ marker }: StatefulMarkerProperties) {
  const [fullMarker, setFullMarker] = useState(
    undefined as StoredMarker | undefined
  );

  if (marker?.subcategoryId === undefined) {
    return null;
  }
  if (
    IdToCategory[marker?.subcategoryId.toString()] === undefined ||
    categoriesIconsList[IdToCategory[marker?.subcategoryId.toString()]] ===
      undefined
  ) {
    return null;
  }

  const greenIcon = L.icon({
    iconUrl:
      categoriesIconsList[IdToCategory[marker?.subcategoryId.toString()]]
        .urlMap,
    iconSize: [32, 36],
    iconAnchor: [12, 36],
  });

  async function fetchFullMarker(id: string) {
    const newMarker = await requestFullMarker(id);
    setFullMarker(newMarker);
  }

  return (
    <Marker
      key={marker._id}
      position={[marker.position.latitude, marker.position.longitude]}
      icon={greenIcon}
    >
      <Popup
        onOpen={() => {
          if (fullMarker === undefined) {
            fetchFullMarker(marker._id);
          }
        }}
      >
        {fullMarker !== undefined ? (
          <div className="info-window">
            {fullMarker.verified ? (
              <a
                className="github-fork-ribbon right-bottom fixed"
                href="#"
                data-ribbon="Producător verificat"
                title="Producător verificat"
              >
                Producător verificat
              </a>
            ) : null}

            <h6>{fullMarker.title}</h6>
            <p>
              {"Categorie: " + IdToCategory[fullMarker.categoryId]}
              <br></br>
              {"Sub-Categorie: " + IdToCategory[fullMarker.subcategoryId]}
            </p>
            {fullMarker.montan ? (
              <LazyLoadImage
                src={pmontan}
                alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
                className="poza_montan"
              />
            ) : null}
            <p className="marker_description">{fullMarker.description}</p>
            {fullMarker.phone !== "Indisponibil" ? (
              window.innerWidth < 1000 ? (
                <Button
                  className="mb-2"
                  as="a"
                  href={"tel:" + fullMarker.phone}
                >
                  Suna acum!
                </Button>
              ) : (
                <Button
                  className="mb-2"
                  as="a"
                  href={"tel:" + fullMarker.phone}
                  onClick={() => {
                    const el = document.createElement("textarea");
                    el.value = marker.phone;
                    document.body.appendChild(el);
                    el.select();
                    document.execCommand("copy");
                    document.body.removeChild(el);
                  }}
                >
                  {fullMarker.phone}
                </Button>
              )
            ) : null}
            <Button
              className="mb-2"
              as="a"
              href={
                "https://www.google.com/maps?saddr=My+Location&daddr=" +
                fullMarker.position.latitude +
                "," +
                fullMarker.position.longitude
              }
            >
              <i className="fa fa-map-marker" aria-hidden="true">
                {" "}
                Spre locație
              </i>
            </Button>

            {fullMarker.verified ? (
              <Button
                className="mb-3"
                as="a"
                href={"/producatori/" + fullMarker.customRoute}
                target="_blank"
              >
                Profil producător
              </Button>
            ) : fullMarker.website !== undefined &&
              fullMarker.website !== null &&
              fullMarker.website !== "" &&
              fullMarker.website[0] !== "#" ? (
              fullMarker.website.includes("@") ? (
                <Button
                  className="mb-3"
                  as="a"
                  href={"mailto:" + fullMarker.website}
                  target="_blank"
                >
                  Email
                </Button>
              ) : (
                <Button
                  className="mb-3"
                  as="a"
                  href={fullMarker.website}
                  target="_blank"
                >
                  Website
                </Button>
              )
            ) : null}

            {/* {
                    (fullMarker.website !== undefined && fullMarker.website !== '' && fullMarker.website[0] !== '#') ?
                    (fullMarker.website.includes("@") ?
                        <Button className="mb-3" as="a" href={"mailto:" + fullMarker.website} target='_blank'>Email</Button> :
                        <Button className="mb-3" as="a" href= {fullMarker.website} target='_blank'>Website</Button>) : null
                } */}
          </div>
        ) : null}
      </Popup>
    </Marker>
  );
}

export default StatefulMarker;
