import { ApplicationState } from "../reducers";
import { defaultFiltersState } from "../reducers/filters";
import {
  markersRequestFiltered,
  markersReceiveFiltered,
} from "../actions/markers";
import { requestFilteredMarkers } from "../../Database";
import { MarkersStore } from "../../schemas/Marker";
import FiltersStore from "../../schemas/Filters";
import { setDispatchers } from "../reducers/markers";
import store from "../index";

export const stateToMarkersProps = (state: ApplicationState) => {
  return {
    markers: state.markers.markers,
  };
};

export const markersReceivedDispatcher = (filters: FiltersStore) => {
  requestFilteredMarkers((newState: MarkersStore) => {
    store.dispatch(markersReceiveFiltered(newState));
  }, filters);
};

setDispatchers(markersReceivedDispatcher);
store.dispatch(markersRequestFiltered(defaultFiltersState));
