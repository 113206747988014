import React from "react";
import { Card, Button, Form, Accordion } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { CategoriesMap, categoriesIconsList } from "../../schemas/Category";

interface CategoryCardProperties {
  name: string;
  categories: CategoriesMap;
  updateFilter: Function;
}

function CategoryCard(props: CategoryCardProperties) {
  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <Card.Header>
          {props.name}
          <Accordion.Toggle as={Button} eventKey="0">
            <FontAwesomeIcon icon={faCaretDown} />
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <div>
              {Object.keys(props.categories).map((key) => {
                const category = props.categories[key];
                return (
                  <Form.Check type="checkbox" key={key}>
                    <Form.Check.Input
                      id={key}
                      type="checkbox"
                      onChange={() => {
                        props.updateFilter(category);
                      }}
                    />
                    <Form.Check.Label htmlFor={key}>
                      <LazyLoadImage
                        src={
                          category.icon !== undefined
                            ? category.icon
                            : categoriesIconsList[key].urlMap
                        }
                        className="small_rounded_image"
                      />
                      {category.name}
                    </Form.Check.Label>
                  </Form.Check>
                );
              })}
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

export default CategoryCard;
