import React, { useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import "../../styles/App.css";
import Navigation from "../stateless/Navigation";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import {
  categoriesMap,
  countyMap,
  CategoryToId,
  CountyToId,
} from "../../schemas/Category";
import ReCAPTCHA from "react-google-recaptcha";
import { registerMarker } from "../../Database";
import { Redirect, useLocation } from "react-router-dom";

interface Position {
  lat: number;
  lng: number;
}

const charTranslation = [
  [/Ă/g, "A"],
  [/ă/g, "a"],
  [/Â/g, "A"],
  [/â/g, "a"],
  [/Î/g, "I"],
  [/î/g, "i"],
  [/Ș/g, "S"],
  [/ș/g, "s"],
  [/Ț/g, "T"],
  [/ț/g, "t"],
] as any;

export default function LocationPicker(this: any) {
  const referral = new URLSearchParams(useLocation().search).get("referral");

  const agreeRef: any = React.createRef();
  const [invalidAgree, setInvalidAgree] = useState(false);
  const [captcha, setCaptcha] = useState("");

  const fieldsRef: any = {};
  const mandatoryFieldsKeys = ["title", "description", "phone", "email"];
  const allFieldsKeys = [
    ...mandatoryFieldsKeys,
    "county",
    "category",
    "subcategory",
    "legal",
    "website",
    "facebook",
    "country",
  ];
  const [mandatoryFieldsInvalid, setMandatoryFieldsInvalid] = useState(
    {} as any
  );
  for (const key of allFieldsKeys) {
    fieldsRef[key] = React.createRef();
  }

  const [currentCategory, setCurrentCategory] = useState(
    Object.keys(categoriesMap)[0] as string
  );
  const [currentCountry, setCurrentCountry] = useState("România");
  const [mapAlert, setMapAlert] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const [marker, setMarker] = useState(undefined as Position | undefined);
  const addMarker = (e: { latlng: any }) => {
    setMarker(e.latlng);
  };

  function changeCategory() {
    setCurrentCategory(fieldsRef.category.current.value);
  }

  function changeCountry() {
    setCurrentCountry(fieldsRef.country.current.value);
  }

  function validateForm() {
    let valid = true;

    if (marker === undefined) {
      setMapAlert(true);
      valid = false;
    } else {
      setMapAlert(false);
    }

    if (captcha === "") {
      valid = false;
    }

    if (agreeRef.current.checked === false) {
      valid = false;
      setInvalidAgree(true);
    } else {
      setInvalidAgree(false);
    }

    const newState = { ...mandatoryFieldsInvalid };
    for (const field of mandatoryFieldsKeys) {
      if (
        fieldsRef[field].current.value === "" ||
        fieldsRef[field].current.value === undefined
      ) {
        newState[field] = true;
        valid = false;
      } else {
        newState[field] = false;
      }
    }
    setMandatoryFieldsInvalid(newState);

    if (valid) {
      const newEntity: any = {};
      for (const field of allFieldsKeys) {
        if (fieldsRef[field].current.value !== "") {
          newEntity[field] = fieldsRef[field].current.value;
        }
      }

      for (const exp of charTranslation) {
        newEntity.description = newEntity.description.replace(exp[0], exp[1]);
        newEntity.title = newEntity.title.replace(exp[0], exp[1]);
      }

      newEntity.categoryId = CategoryToId[newEntity.category];
      if (newEntity.subcategory !== "" && newEntity.subcategory !== undefined) {
        newEntity.subcategoryId = CategoryToId[newEntity.subcategory];
      } else {
        newEntity.subcategoryId = CategoryToId[newEntity.category];
      }
      if (newEntity.county !== "" && newEntity.county !== undefined) {
        newEntity.countyId = CountyToId[newEntity.county];
      } else {
        newEntity.countyId = CountyToId["Republica Moldova"];
      }
      delete newEntity.category;
      delete newEntity.subcategory;
      delete newEntity.county;

      newEntity.captcha = captcha;
      newEntity.position = {
        latitude: marker?.lat,
        longitude: marker?.lng,
      };

      if (referral === "producatormontan") {
        newEntity.montan = true;
      }

      registerMarker(newEntity);
      setIsFormValid(true);
    }
  }

  const DefaultIcon = L.icon({
    iconUrl: "https://maps.google.com/mapfiles/ms/icons/green-dot.png",
    iconSize: [32, 36],
    iconAnchor: [12, 36],
    // shadowUrl: iconShadow
  });
  L.Marker.prototype.options.icon = DefaultIcon;

  return (
    <div>
      {isFormValid ? <Redirect to="/success" /> : null}
      <Navigation />
      <div className="location-picker">
        <h3 className="heading_inregistrare">Înregistrare producători</h3>
        <h5 className="subheading_inregistrare">
          Împreună putem susține economia Românească.
        </h5>
        <Row xs="1" md="2" lg="2" className="mt-5">
          <Col>
            <Form>
              <Row xs="1" md="1" lg="2">
                <Col>
                  <Form.Label>Țara</Form.Label>
                  <Form.Control
                    ref={fieldsRef.country}
                    as="select"
                    custom
                    onChange={changeCountry}
                  >
                    <option>România</option>
                    <option>Republica Moldova</option>
                  </Form.Control>
                </Col>

                <Col>
                  <Form.Label>Tip producător</Form.Label>
                  <Form.Control ref={fieldsRef.legal} as="select" custom>
                    <option>SRL</option>
                    <option>PFA</option>
                    <option>II</option>
                    <option>IF</option>
                    <option>Producator autorizat</option>
                  </Form.Control>
                </Col>
              </Row>

              <Row xs="1" md="1" lg="2">
                <Col>
                  <Form.Label>Nume firmă</Form.Label>
                  <Form.Control
                    ref={fieldsRef.title}
                    isInvalid={mandatoryFieldsInvalid.title}
                    placeholder="Nume firmă"
                  />
                </Col>
                <Col>
                  <Form.Label>Judet</Form.Label>
                  <Form.Control ref={fieldsRef.county} as="select" custom>
                    {currentCountry === "România"
                      ? Object.keys(countyMap).map((county: string) => {
                          return <option>{county}</option>;
                        })
                      : null}
                  </Form.Control>
                </Col>
              </Row>

              <Row xs="1" md="1" lg="2">
                <Col>
                  <Form.Label>Categorie</Form.Label>
                  <Form.Control
                    ref={fieldsRef.category}
                    as="select"
                    custom
                    onChange={changeCategory}
                  >
                    {Object.keys(categoriesMap).map((category: string) => {
                      return <option>{category}</option>;
                    })}
                  </Form.Control>
                </Col>

                <Col>
                  <Form.Label>Sub-Categorie</Form.Label>
                  <Form.Control ref={fieldsRef.subcategory} as="select" custom>
                    {Object.keys(
                      categoriesMap[currentCategory].subcategories!
                    ).map((category: string) => {
                      return <option>{category}</option>;
                    })}
                  </Form.Control>
                </Col>
              </Row>

              <Row xs="1" md="1" lg="3">
                <Col>
                  <Form.Label>Telefon</Form.Label>
                  <Form.Control
                    ref={fieldsRef.phone}
                    isInvalid={mandatoryFieldsInvalid.phone}
                    placeholder="Numărul dvs"
                  />
                </Col>

                <Col>
                  <Form.Label>Website</Form.Label>
                  <Form.Control
                    ref={fieldsRef.website}
                    placeholder="Website (opțional)"
                  />
                </Col>

                <Col>
                  <Form.Label>Facebook</Form.Label>
                  <Form.Control
                    ref={fieldsRef.facebook}
                    placeholder="link Facebook (opțional)"
                  />
                </Col>
              </Row>

              <Row xs="1" md="1" lg="2">
                <Col>
                  <Form.Label>Descriere producător</Form.Label>
                  <Form.Control
                    ref={fieldsRef.description}
                    isInvalid={mandatoryFieldsInvalid.description}
                    placeholder="Descriere firmă (maxim 80-100 caractere)"
                    as="textarea"
                    rows={3}
                  />
                </Col>

                <Col>
                  <Row xs="1">
                    <Col>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        ref={fieldsRef.email}
                        isInvalid={mandatoryFieldsInvalid.email}
                        type="email"
                        placeholder="Email"
                      />
                    </Col>
                    <Col className="mt_mobile">
                      <Form.Check
                        ref={agreeRef}
                        isInvalid={invalidAgree}
                        type="checkbox"
                        label="Sunt de acord cu termenii și condițiile producatoridinromania.ro."
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row id="formGridCheckbox" className="mt-4" xs="1" md="1" lg="1">
                {/* <Col className="mt-2 mb-2">
                  <ReCAPTCHA
                  sitekey="6LftvncUAAAAAO9Ds3yLQULEhNpB7u4l3AMiFbXV"
                  onChange={(value: any) => { setCaptcha(value); }}
                  />
              </Col> */}
              </Row>
            </Form>
          </Col>
          <Col>
            <Form.Label>Selectați locația afacerii dumneavoastra:</Form.Label>
            <Map center={[46.0189455, 25.379505]} onClick={addMarker} zoom={6}>
              {mapAlert ? (
                <Alert variant="danger" className="map-message">
                  Va rugam sa selectati locatia afacerii dumneavoastra.
                </Alert>
              ) : null}
              <TileLayer
                attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
              />
              {marker !== undefined ? (
                <Marker position={marker}>
                  <Popup>Locația dvs</Popup>
                </Marker>
              ) : null}
            </Map>
          </Col>
        </Row>
        <Row
          className="d-flex justify-content-center mt-4"
          lg="1"
          xs="1"
          md="1"
        >
          <Col className="d-flex justify-content-center mt-2">
            <ReCAPTCHA
              sitekey="6LftvncUAAAAAO9Ds3yLQULEhNpB7u4l3AMiFbXV"
              onChange={(value: any) => {
                setCaptcha(value);
              }}
            />
          </Col>
          <Col className="d-flex justify-content-center mt-2">
            <Button variant="primary" type="submit" onClick={validateForm}>
              Înscrie-te
            </Button>
          </Col>
          {/* <a href="/success" className="mt-3"> */}
          {/* <Button variant="primary" type="submit" onClick={validateForm}>
                    Înscrie-te
                  </Button> */}
          {/* </a> */}
        </Row>
      </div>
    </div>
  );
}
