import React from "react";
import "../../styles/App.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import StatefulMapContainer from "../stateful/StatefulMapContainer";
import Navigation from "../stateless/Navigation";
import logo from "../../img/logo_transparent.png";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "bootstrap/dist/css/bootstrap.min.css";
import Pricing from "./Pricing";
import Footer from "./Footer";
import CarouselPromovate from "../stateful/CarouselPromovate";
import pozaMisu from "../../img/pozaMisu.jpg";
import pozaAndrei from "../../img/pozaAndrei.jpg";
import pozaItgenetics from "../../img/itgenetics.jpg";

export default function Landing() {
  return (
    <Container className="lp-containter no-sm-padding scrollable" fluid>
      <Row className="vh-25">
        <Col>
          <Navigation />
        </Col>
      </Row>
      <Row className="mt-2 firs_row_lp" xs="1" lg="2">
        <Col>
          <div className="div_col1_lp">
            <h4>INTRODUCERE</h4>
            <h2>HARTA PRODUCĂTORILOR LOCALI</h2>
            <p>
              Bine ai venit pe platforma noastră! Dacă ești producător te
              invităm gratuit{" "}
              <a href="/inregistrare">să te înscri în platformă</a> pentru a
              avea o deschidere cât mai mare către consumatori. Dacă ești
              consumator, investitor sau simpatizant ai conceptului acestei
              platforme te invităm să citești mai jos, dar și să accesezi zonele
              site-ului nostru: <a href="/harta">zona de hartă</a>,{" "}
              <a href="/listing">zona de listare</a> și{" "}
              <a href="/premium">zona premium</a>.
            </p>
            <div className="first_row_buttons">
              <Link to="/harta">
                <Button color="primary" size="lg" active>
                  Spre Harta
                </Button>
              </Link>
              <a href="/inregistrare">
                <Button
                  variant="outline-primary"
                  size="lg"
                  className="button_inscriere"
                >
                  Înscrie-te acum!
                </Button>
              </a>
            </div>
          </div>
        </Col>
        <Col>
          <div className="div_col2_lp">
            <LazyLoadImage
              src={logo}
              id="logo_lp"
              alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
            />
          </div>
        </Col>
      </Row>

      <Row className="mt-2 second_row_lp" xs="1" lg="2">
        <Col>
          <div className="div_col2_lp">
            <StatefulMapContainer />
          </div>
        </Col>
        <Col>
          <div className="div_col1_lp">
            <h4>DETALII</h4>
            <h2>DESPRE PLATFORMĂ</h2>
            <p>
              Platforma <b>Harta Producătorilor Locali</b> este un proiect prin
              care se încearcă crearea unui eco-sistem dintre producători și
              consumatori (fie ei persoane fizice, fie juridice). Platforma este
              foarte intuitivă, atât pentru consumatori cât și pentru
              producători. Printre modulele disponibile în platformă regăsim{" "}
              <b>Harta</b> în care se poate vedea așezarea producătorilor în
              țară, dar și <b>listarea</b> producătorilor unde se poate vedea o
              listă cu ei. Pentru a ușura găsirea anumitor producători,
              filtrarea se poate face după:{" "}
              <b>categorie, județ și cuvinte cheie</b>.
            </p>
            <div className="first_row_buttons">
              <Link to="/listing">
                <Button
                  variant="outline-primary"
                  size="lg"
                  className="button_inscriere"
                >
                  Listare
                </Button>
              </Link>
              <Link to="/harta">
                <Button color="primary" size="lg" active>
                  Harta
                </Button>
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="third_row_lp">
        <Col>
          <div className="div_col1_lp">
            <h4>HARTA PRODUCĂTORILOR LOCALI</h4>
            <h2>Pentru producători</h2>
            <p>
              Dacă ești producător și vrei să te promovezi gratuit, nu ezita să
              te înscrii în platforma noastră, este foarte simplu! Mergi în
              secțiunea Înscriere și completează formularul cu date corecte, iar
              în 24-48H vei apărea în platformă!
            </p>
            <p>
              Dacă dorești ca pe lângă promovarea gratuită din cadrul
              platformei, să ai propriul website de prezentare, un logo nou sau
              să ai un loc special în platforma noastră, și în același timp vrei
              să ne și sprijini, poți opta pentru unul dintre pachetele noastre
              pe care le vom publica în platformă la prețuri minime (câteva beri
              pe lună :D).
            </p>
            <a target="blank" href="/inregistrare">
              <Button color="primary" size="lg" active>
                Înscrie-te acum!
              </Button>
            </a>
          </div>
        </Col>
        <Col>
          <div className="div_col1_lp">
            <h4>HARTA PRODUCĂTORILOR LOCALI</h4>
            <h2>Pentru consumatori</h2>
            <p>
              Consumatorii din platforma noastră beneficiază gratuit de listarea
              producătorilor autenitici din România și Republica Moldova. Pentru
              fiecare dintre producătorii din platformă poți vedea o descriere
              succintă, numele firmei și ai acces foarte ușor la datele de
              contact. Totul este foarte intuitiv și ușor de folosit, fiecare
              producător este reprezentat pe hartă de o iconiță specifică
              categoriei din care face parte, poți filtra după categorie și
              județ, iar în viitorul apropiat vei putea căuta și după cuvinte
              cheie.
            </p>
            <p>
              Dacă ești consumator și dorești să ne susții și să adăugam cât mai
              multe module acestei platforme, ajută-ne cu un{" "}
              <a href="https://www.facebook.com/hartaproducatorilromani">
                share
              </a>
              .
            </p>
            <Link to="/harta">
              <Button color="primary" size="lg" active>
                Caută producători!
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
      <CarouselPromovate />
      <Row className="mt-5 firs_row_lp" xs="1" lg="1">
        {/* <Col>
                <div className="div_col2_lp">
                    <LazyLoadImage src={logo} id="logo_lp" alt="producatori din romania, harta producatorilor din romania"/>
                </div>
            </Col> */}
        <Col>
          <div className="div_col1_lp">
            <h4>ZONA PREMIUM</h4>
            <h2>HARTA PRODUCĂTORILOR LOCALI ROMANI</h2>
            <p>
              Prin cumpărarea unuia dintre pachetele premium ale platformei
              noastre ne puteți ajuta să continuăm dezvoltarea platformei, și
              totodata vă puteți da un boost în ceea ce privește imaginea firmei
              și promovarea către consumatori (persoane fizice și juridice).
              Pentru a comanda unul dintre pachetele premium, ne puteti contacta
              la adresa <b>andrei@utilsoftwaresolutions.ro</b> sau pe{" "}
              <a href="https://www.facebook.com/hartaproducatorilromani/">
                Facebook
              </a>
              .
            </p>
            <div className="first_row_buttons">
              <a href="/harta">
                <Button color="primary" size="lg" active>
                  Spre Harta
                </Button>
              </a>
              <a href="https://tawk.to/chat/5e9ed12e35bcbb0c9ab336b1/1e73gdm3b">
                <Button
                  variant="outline-primary"
                  size="lg"
                  className="button_inscriere"
                >
                  Mai multe detalii
                </Button>
              </a>
            </div>
          </div>
        </Col>
      </Row>
      <Pricing />

      <h1
        style={{ textAlign: "center", paddingBottom: "30px" }}
        className="title_echipa"
      >
        Echipă și contact
      </h1>
      <Row xs="1" lg="2" className="echipa">
        <Col>
          <LazyLoadImage
            className="poze_echipa rounded"
            alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
            src={pozaAndrei}
          />
          <h4>Ilie Andrei-Leonard</h4>
          <h5>Co-founder</h5>
          <a href="https://www.linkedin.com/in/ilie-andrei-leonard/">
            <LazyLoadImage
              alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
              className="social"
              src="https://img.icons8.com/clouds/128/000000/linkedin.png"
            />
          </a>
          <a href="https://www.facebook.com/andrei.ilie.397501">
            <LazyLoadImage
              alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
              className="social"
              src="https://img.icons8.com/clouds/128/000000/facebook-new.png"
            />
          </a>
          <a href="mailto:andrei@utilsoftwaresolutions.ro">
            <LazyLoadImage
              alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
              className="social"
              src="https://img.icons8.com/clouds/128/000000/email.png"
            />
          </a>
        </Col>
        <Col>
          <LazyLoadImage
            className="poze_echipa rounded"
            alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
            src={pozaMisu}
          />
          <h4>Feraru Mihail</h4>
          <h5>Co-founder</h5>
          <a href="https://www.linkedin.com/in/mihail-feraru/">
            <LazyLoadImage
              alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
              className="social"
              src="https://img.icons8.com/clouds/128/000000/linkedin.png"
            />
          </a>
          <a href="https://www.facebook.com/ferarumihail">
            <LazyLoadImage
              alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
              className="social"
              src="https://img.icons8.com/clouds/128/000000/facebook-new.png"
            />
          </a>
          <a href="mailto:mihailferaru2000@gmail.com">
            <LazyLoadImage
              alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
              className="social"
              src="https://img.icons8.com/clouds/128/000000/email.png"
            />
          </a>
        </Col>
      </Row>
      <Row className="third_row_lp">
        <Col>
          <div className="div_col1_lp">
            <h4>PARTENERIAT</h4>
            <h2>IT Genetics ofera gratuit setul "Produs in Romania"</h2>
            <p>
              Din dorinta de a sprijini producatorii locali, in perioada 20
              iulie - 31 octombrie 2020, IT Genetics ofera gratuit setul PRODUS
              IN ROMANIA. Setul include 500 de etichete si o banda adeziva.
              Special conceput pentru ca micii producatori din tara sa isi poata
              eticheta si promova produsele fara costuri suplimentare, acesta
              poate fi obtinut accesand pagina campaniei.
            </p>
            <a
              href="https://www.itgstore.ro/business-kit/produs-in-romania?utm_source=site-harta-producatori-locali&utm_medium=referral&utm_campaign=produs-in-romania"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button color="primary" size="lg" active>
                Comanda acum gratuit
              </Button>
            </a>
          </div>
        </Col>
        <Col>
          <div className="div_col1_lp">
            <LazyLoadImage width="60%" height="60%" src={pozaItgenetics} />
          </div>
        </Col>
      </Row>
      <Row id="parteneri">
        <Col>
          <div className="div_col1_lp">
            <h4>HARTA PRODUCĂTORILOR LOCALI</h4>
            <h2>Parteneri MEDIA</h2>
            <p>
              Dacă ești un factor media și dorești să ne sprijini sau dorești să
              scrii un articol despre noi și platforma noastră, nu ezita să ne
              contactezi pe email:{" "}
              <a href="mailto:>andrei@utilsoftwaresolutions.ro">
                andrei@utilsoftwaresolutions.ro
              </a>{" "}
              sau prin chat-ul de pe website și să devii unul dintre partenerii
              noștrii media.
            </p>
            <a href="/partenerim">
              <Button color="primary" size="lg" active>
                Parteneri media
              </Button>
            </a>
          </div>
        </Col>
        <Col>
          <div className="div_col1_lp">
            <h4>HARTA PRODUCĂTORILOR LOCALI</h4>
            <h2>Parteneri STRATEGICI</h2>
            <p>
              De la primării și până la investitori, partenerii noștrii
              strategici au văzut un potențial în noi, dacă și tu vezi acest
              potențial în noi sau în platformă nu ezita să ne contactezi pe
              email{" "}
              <a href="mailto:>andrei@utilsoftwaresolutions.ro">
                andrei@utilsoftwaresolutions.ro
              </a>{" "}
              sau prin chat-ul de pe website pentru a programa o discuție și nu
              numai.
            </p>
            <a href="/parteneris">
              <Button color="primary" size="lg" active>
                Parteneri strategici
              </Button>
            </a>
          </div>
        </Col>
      </Row>
      {/* <MediaLogoSliders /> */}
      <Row xs="1" lg="1">
        <Footer />
      </Row>
    </Container>
  );
}
