import Listing from "../stateless/Listing";
import React, { useState, useEffect } from "react";
import { Pagination } from "react-bootstrap";
import { stateToFiltersProps } from "../../state/dispatchers/filters";
import { connect } from "react-redux";
import FiltersStore from "../../schemas/Filters";
import { Marker } from "../../schemas/Marker";
import { requestFullMarkersPaginated, getPagesCount } from "../../Database";

interface StatefulListingInternalProperties {
  filters: FiltersStore;
}

function StatefulListingInternal({
  filters,
}: StatefulListingInternalProperties) {
  const [pagesCount, setPageCount] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [markers, setMarkers] = useState([] as Marker[]);

  const itemsPerPage = 10;
  const showPagesCount = 1;

  async function fetchPageCount() {
    const count = await getPagesCount(filters, itemsPerPage);
    setPageCount(count);
  }

  async function fetchPage(i: number) {
    setActivePage(i);
    const dataset = await requestFullMarkersPaginated(
      filters,
      itemsPerPage,
      i + 1
    );
    setMarkers(dataset);
  }

  async function fetchDataset() {
    fetchPageCount();
    fetchPage(0);
  }

  useEffect(() => {
    fetchDataset();
  }, [filters]);

  const pageItems = [];

  let startPage =
    activePage - showPagesCount < 0 ? 0 : activePage - showPagesCount;
  let endPage = activePage + showPagesCount;

  if (activePage === 0) endPage++;
  else if (activePage === pagesCount - 1) startPage--;
  if (startPage < 0) startPage = 0;

  for (let i = startPage; i <= endPage && i < pagesCount; ++i) {
    pageItems.push(
      <Pagination.Item
        key={i}
        active={i === activePage}
        onClick={() => {
          fetchPage(i);
        }}
      >
        {i + 1}
      </Pagination.Item>
    );
  }

  return (
    <>
      <Listing markers={markers} />
      <Pagination className="mt-2 d-flex justify-content-center">
        {activePage !== 0 ? (
          <>
            <Pagination.First
              onClick={() => {
                fetchPage(0);
              }}
            />
            <Pagination.Prev
              onClick={() => {
                fetchPage(activePage - 1);
              }}
            />
          </>
        ) : null}
        {pageItems}
        {activePage !== pagesCount - 1 ? (
          <>
            <Pagination.Next
              onClick={() => {
                fetchPage(activePage + 1);
              }}
            />
            <Pagination.Last
              onClick={() => {
                fetchPage(pagesCount - 1);
              }}
            />
          </>
        ) : null}
      </Pagination>
    </>
  );
}

const StatefulListing = connect(stateToFiltersProps)(StatefulListingInternal);

export default StatefulListing;
