import React, { useEffect, useState } from "react";
import MapPosition from "../../schemas/MapPosition";
import { Marker as StoredMarker } from "../../schemas/Marker";
import { Map, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { connect } from "react-redux";
import { stateToMarkersProps } from "../../state/dispatchers/markers";
import StatefulMarker from "./StatefulMarker";
import { marker } from "leaflet";
import Alert from "react-bootstrap/Alert";
import store from "../../state/index";

interface MapContainerProperties {
  markers: StoredMarker[];
}

const RomaniaPosition: MapPosition = {
  latitude: 46.0189455,
  longitude: 25.379505,
};
const proximityZoom = 10;

function GoogleMapPosition(position: MapPosition) {
  return { lat: position.latitude, lng: position.longitude };
}

function MapContainer({ markers }: MapContainerProperties) {
  const [centerPosition, setCenterPosition] = useState(RomaniaPosition);

  function centerMapView() {
    navigator.geolocation.getCurrentPosition((position) => {
      setCenterPosition({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });

      return position;
    });
  }

  function getCenterPosition() {
    return GoogleMapPosition(centerPosition);
  }

  useEffect(() => {
    centerMapView();
  }, []);

  return (
    <Map
      center={getCenterPosition()}
      zoom={proximityZoom}
      className="markercluster-map"
    >
      {markers.length === 0 &&
      (Object.keys(store.getState().filters.Categorie).length !== 0 ||
        Object.keys(store.getState().filters.Judet).length !== 0 ||
        store.getState().filters.text !== "") ? (
        <Alert variant="danger" className="map-message">
          Nu sunt producători conform filtrelor dvs.
        </Alert>
      ) : null}
      <TileLayer
        attribution='&amp;copy <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MarkerClusterGroup>
        {markers.map((marker) => {
          return <StatefulMarker key={marker._id} marker={marker} />;
        })}
      </MarkerClusterGroup>
    </Map>
  );
}

const StatefulMapContainer = connect(stateToMarkersProps)(MapContainer);

export default StatefulMapContainer;
