import React from "react";
import "../../styles/NotFoundPage.css";
import Navigation from "./Navigation";
import Footer from "./Footer";
import { Button } from "react-bootstrap";
export default function SuccessfullRegister() {
  return (
    <div>
      <Navigation />
      <div className="error">
        <div className="container-floud">
          <div className="col-xs-12 ground-color text-center">
            <div className="container-error-404">
              <div className="clip">
                <div className="shadow">
                  <span className="digit thirdDigit">Su</span>
                </div>
              </div>
              <div className="clip">
                <div className="shadow">
                  <span className="digit secondDigit">cc</span>
                </div>
              </div>
              <div className="clip">
                <div className="shadow">
                  <span className="digit firstDigit">es</span>
                </div>
              </div>
              <div className="msg">
                :)<span className="triangle"></span>
              </div>
            </div>
            <h2 className="h1">
              Felicitări! Te-ai înscris cu succes în platforma noastră!
            </h2>
            <h2 style={{ fontSize: 20 }}>
              <br></br>Vom verifica înscrierea ta și vei apărea în platformă!
            </h2>
            <br></br>
            <a href="/">
              <Button variant="primary" type="submit" className="mt-4">
                Acasă
              </Button>
            </a>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
