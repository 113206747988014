import React from "react";
import "./styles/App.css";
import { Container, Row, Col } from "react-bootstrap";
import Navigation from "./components/stateless/Navigation";
import { BrowserRouter, Route, Prompt, Switch } from "react-router-dom";
import TermeniConditii from "./components/stateless/TermeniConditii";
import Landing from "./components/stateless/Landing";
import firebase from "./firebaseApp";
import MapView from "./components/stateless/MapView";
import { dispatchFiltersReset } from "./state/dispatchers/filters";
import ListingView from "./components/stateless/ListingView";
import PaginaProducator from "./components/stateful/PaginaProducator";
import Footer from "./components/stateless/Footer";
import NotFoundPage from "./components/stateless/NotFoundPage";
import Register from "./components/stateful/LocationPicker";
import Premium from "./components/stateless/Premium";
import SuccessfullRegister from "./components/stateless/SuccessfullRegister";
import CarouselPromovate from "./components/stateful/CarouselPromovate";
import MediaLogoSliders from "./components/stateless/MediaLogoSliders";
// Analytics
firebase.analytics().logEvent("page_loading");
// --------------------

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/harta">
          <Prompt
            message={() => {
              dispatchFiltersReset();
              return true;
            }}
          />
          <MapView />
        </Route>

        <Route exact path="/listing">
          <Prompt
            message={() => {
              dispatchFiltersReset();
              return true;
            }}
          />
          <ListingView />
        </Route>

        <Route exact path="/">
          <Landing />
        </Route>
        <Route
          path="/producatori/:route"
          render={(props) => {
            return <PaginaProducator customRoute={props.match.params.route} />;
          }}
        />
        <Route exact path="/termeni">
          <Container className="lp-containter no-sm-padding scrollable" fluid>
            <Row className="vh-25">
              <Col>
                <Navigation />
              </Col>
            </Row>
            <TermeniConditii />
            <Footer />
          </Container>
        </Route>
        <Route exact path="/test">
          <CarouselPromovate />
        </Route>
        <Route exact path="/inregistrare">
          <Register />
        </Route>
        <Route exact path="/premium">
          <Premium />
        </Route>
        <Route exact path="/partenerim">
          <Container className="lp-containter no-sm-padding scrollable" fluid>
            <Row className="vh-25">
              <Col>
                <Navigation />
              </Col>
            </Row>
            <div className="margins_top">
              <MediaLogoSliders />
            </div>
          </Container>
          <Footer />
        </Route>
        <Route exact path="/success">
          <SuccessfullRegister />
        </Route>
        <Route path="*">
          <Navigation />
          <NotFoundPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
