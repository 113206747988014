import React, { Component, useState, useEffect } from "react";
import Slider from "react-slick";
import { Card, Button, Badge } from "react-bootstrap";
import "../../styles/slider.css";
import { Marker as StoredMarker } from "../../schemas/Marker";
import { requestFullMarkersPaginated } from "../../Database";
import { defaultFiltersState } from "../../state/reducers/filters";
import { IdToCategory } from "../../schemas/Category";
import shuffle from "shuffle-array";

export default function CarouselPromovate() {
  const [markers, setMarkers] = useState([] as StoredMarker[]);

  async function fetchMarkers() {
    const newMarkers = await requestFullMarkersPaginated(
      defaultFiltersState,
      20,
      0,
      true
    );
    const shuffledMarkers = shuffle(newMarkers, { copy: true });
    setMarkers(shuffledMarkers);
  }

  useEffect(() => {
    fetchMarkers();
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };
  return (
    <div className="carousel">
      <Slider {...settings}>
        {markers.map((marker) => {
          return (
            <div className="divx" key={marker.title}>
              <Card>
                <Card.Img
                  variant="top"
                  src={marker.logoImage}
                  className="card_img"
                  alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
                />
                <Card.Body>
                  <Card.Title>
                    {marker.title}
                    <Badge variant="success" className="testt">
                      Verificat
                    </Badge>
                  </Card.Title>
                  <Card.Text>
                    <b>Categorie:</b> {IdToCategory[marker.subcategoryId]}
                  </Card.Text>
                  <Card.Text>
                    {marker.description.substr(0, 50)}
                    <a href={"/producatori/" + marker.customRoute}> ...</a>
                  </Card.Text>
                  <div className="first_row_buttons">
                    <a href={"/producatori/" + marker.customRoute}>
                      <Button color="primary" active>
                        Profil
                      </Button>
                    </a>
                    <a href={"tel:" + marker.phone}>
                      <Button color="primary" active>
                        Contact
                      </Button>
                    </a>
                  </div>
                </Card.Body>
              </Card>
            </div>
          );
        })}
        <div className="divx">
          <Card>
            <Card.Img
              variant="top"
              src="https://producatoridinromania.ro//static/media/logo_transparent.b5cb9d16.png"
              className="card_img"
              alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
            />
            <Card.Body>
              <Card.Title>
                Firma ta
                <Badge variant="success" className="testt">
                  Verificat
                </Badge>
              </Card.Title>
              <Card.Text>
                <b>Categorie:</b> PREMIUM
              </Card.Text>
              <Card.Text>
                Dacă vrei să ne susții poți să cumperi un pachet premium pentru
                a fi și firma ta aici!<a href={"/producatori/dealbine"}> ...</a>
              </Card.Text>
              <div className="first_row_buttons">
                <a href={"/premium"}>
                  <Button color="primary" active>
                    Premium
                  </Button>
                </a>
                <a href={"mailto:andrei@utilsoftwaresolutions.ro"}>
                  <Button color="primary" active>
                    Contact
                  </Button>
                </a>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Slider>
    </div>
  );
}
