import firebase from "firebase/app";
import "firebase/analytics";

firebase.initializeApp({
  apiKey: "AIzaSyBlWYs5BvF3emYflVhUD9GhFh-P9XAAmrk",
  appId: "1:314978050558:web:552b03924e6e5e31c620af",
  authDomain: "producatorilocali.firebaseapp.com",
  databaseURL: "https://producatorilocali.firebaseio.com",
  measurementId: "G-G86NVJGEJS",
  messagingSenderId: "314978050558",
  projectId: "producatorilocali",
  storageBucket: "producatorilocali.appspot.com",
});

export default firebase;
