import React, { useState, useEffect } from "react";
import { Container, Row, Col, Badge } from "react-bootstrap";
import Navigation from "../stateless/Navigation";
import { Card, Button, Carousel, CardGroup } from "react-bootstrap";
import logo from "../../img/logo_transparent.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { Marker as StoredMarker } from "../../schemas/Marker";
import { requestFullMarkerByRoute } from "../../Database";
import NotFoundPage from "../stateless/NotFoundPage";

interface PaginaProducatorProperties {
  customRoute: string;
}

export default function PaginaProducator({
  customRoute,
}: PaginaProducatorProperties) {
  const [marker, setMarker] = useState(
    undefined as StoredMarker | undefined | false
  );

  async function loadMarker() {
    const fetchedMarker = await requestFullMarkerByRoute(customRoute);
    if ((fetchedMarker as any).statusCode === 500) {
      setMarker(false);
      return;
    }
    setMarker(fetchedMarker);
  }

  useEffect(() => {
    loadMarker();
  }, [loadMarker]);

  if (marker === undefined) return <></>;
  if (marker === false)
    return (
      <>
        <Navigation />
        <NotFoundPage />
      </>
    );

  return (
    <Container className="vh-100 main-container no-sm-padding" fluid>
      <Row>
        <Col>
          <Navigation />
        </Col>
      </Row>

      <Row className="mt-2 firs_row_lp" xs="1" lg="2">
        <Col>
          <div className="div_col1_lp">
            <h4>SUSȚINE PRODUCĂTORII LOCALI</h4>
            <h2>{marker.title}</h2>
            <Badge variant="success" className="testt">
              Verificat
            </Badge>
            <div>
              <p>{marker.description}</p>
            </div>

            <div className="first_row_buttons">
              <a href="#productie">
                <Button color="primary" size="lg" active>
                  Producție
                </Button>
              </a>
              <a href="#contact">
                <Button color="primary" size="lg" active>
                  Contact
                </Button>
              </a>
            </div>
          </div>
        </Col>
        <Col>
          <div className="col2_logo_prod d-flex justify-content-center">
            <LazyLoadImage
              src={marker.logoImage}
              id="logo_producator"
              alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2 second_row_lp" xs="1" lg="2">
        <Col>
          <div className="col2_poza_prod d-flex justify-content-center">
            <LazyLoadImage
              src={marker.descriptionImage}
              id="poza_producator"
              alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
            />
          </div>
        </Col>
        <Col>
          <div className="div_col1_lp">
            <h4>SUSȚINE PRODUCĂTORII LOCALI</h4>
            <h2>Despre {marker.title} </h2>
            <p>{marker.fullDescription}</p>
          </div>
        </Col>
      </Row>

      <Row className="third_row_lp d-flex justify-content-center">
        <Col>
          <div className="div_col1_lp ">
            <h4>{marker.title}</h4>
            <h2>Povestea noastră</h2>
            <p>{marker.ourStory}</p>
            <p>
              <i>
                Îți poți spune și tu povestea de producător în platforma
                noastră. <a href="/premium">Contactează-ne</a>
              </i>
            </p>
            <a href={"tel:" + marker.phone}>
              <Button color="primary" size="lg" active>
                Sună acum
              </Button>
            </a>
          </div>
        </Col>

        <Col>
          <div className="div_col1_lp" id="contact">
            <h4>{marker.title}</h4>
            <h2>Contact</h2>
            <p>
              Producătorul verificat <b>{marker.title}</b> din platforma HPLR
              poate fi contactat prin următoarele metode:
            </p>
            <p>
              <i className="fa fa-phone" aria-hidden="true"></i> Număr de
              telefon:{" "}
              <a href={"tel:" + marker.phone}>
                {" "}
                {marker.phone ? marker.phone : "-"}
              </a>
            </p>
            <p>
              <i className="fa fa-at" aria-hidden="true"></i> Email:{" "}
              <a href={"mailto:" + marker.email}>
                {" "}
                {marker.email ? marker.email : "-"}
              </a>
            </p>
            <p>
              <i className="fa fa-mouse-pointer" aria-hidden="true"></i>{" "}
              Website:{" "}
              <a href={marker.website}>
                {" "}
                {marker.website ? marker.website : "-"}
              </a>
            </p>
            <Button
              className="mb-2"
              as="a"
              href={
                "https://www.google.com/maps?saddr=My+Location&daddr=" +
                marker.position.latitude +
                "," +
                marker.position.longitude
              }
            >
              <i className="fa fa-map-marker" aria-hidden="true">
                {" "}
                Spre locație
              </i>
            </Button>
          </div>
        </Col>
      </Row>
      {marker.products ? (
        <Row
          className="mt-2 d-flex justify-content-center"
          xs="1"
          lg="2"
          id="productie"
        >
          <div className="div_col1_lp">
            <h4>SUSȚINE PRODUCĂTORII LOCALI</h4>
            <h2>Ce produce {marker.title} ?</h2>
            <p>
              Producătorul verificat <b>{marker.title}</b> vinde produsele
              listate mai jos. Pentru mai multe detalii și produse nu ezita să
              îl contactezi!
            </p>
            {/* <a href={"tel:" + marker.phone}><Button color="primary" size="lg" active>Comandă acum</Button></a> */}
          </div>
        </Row>
      ) : null}
      {marker.products ? (
        <Row
          className="d-flex justify-content-center products"
          lg="3"
          md="3"
          xs="2"
        >
          {marker.products !== undefined
            ? marker.products.map((product, index) => {
                return (
                  <Card>
                    <Card.Img
                      variant="top"
                      src={product.image}
                      alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
                    />
                    <Card.Body>
                      <Card.Title>{product.name}</Card.Title>
                      <Card.Text>
                        {product.price
                          ? "Preț: " + product.price + "lei"
                          : null}
                      </Card.Text>
                      <a href={"tel:" + marker.phone}>
                        <Button variant="primary">Comandă</Button>
                      </a>
                      {marker.website ? (
                        <a href={marker.website}>
                          <Button variant="primary" className="margin_but_web">
                            Spre website
                          </Button>
                        </a>
                      ) : null}
                    </Card.Body>
                  </Card>
                );
              })
            : null}
        </Row>
      ) : null}
      <Row className="third_row_lp p-4 justify-content-center">
        <h4>Vrei si tu un website de prezentare? </h4>
        <a href="/premium">
          <Button color="primary" size="sm" className="margin_but_web" active>
            Contactează-ne acum!
          </Button>
        </a>
      </Row>

      <Row xs="1" lg="1">
        <footer id="myFooter">
          <div className="container">
            <div className="row ">
              <div className="col-sm-3 d-flex justify-content-center">
                <LazyLoadImage
                  src={marker.logoImage}
                  id="logo_producator"
                  alt="producatori din romania, harta producatorilor din romania, lista producatorilor, producatori locali, producatori romani, producatori"
                />
              </div>
              <div className="col-sm-2">
                <h5>Link-uri utile</h5>
                <ul>
                  <li>
                    <a href="/termeni">Termeni și condiții</a>
                  </li>
                  <li>
                    <a href="#">Politici de confidențialitate</a>
                  </li>
                  <li>
                    <a href="/premium">Susține-ne!</a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-2">
                <h5>Tool-uri</h5>
                <ul>
                  <li>
                    <a href="/harta">Harta Producătorilor</a>
                  </li>
                  <li>
                    <a href="/listing">Listarea Producătorilor</a>
                  </li>
                  <li>
                    <a href="/premium">Pachete pentru producători</a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-3">
                <h5>Contact</h5>
                <p>
                  Pentru orice fel de problemă/bug/sugestie/propunere/susținere
                  nu ezitați să ne contactați pe{" "}
                  <a href="https://www.facebook.com/hartaproducatorilromani">
                    Facebook
                  </a>{" "}
                  sau pe email{" "}
                  <a href="mailto:andrei@utilsoftwaresolutions.ro">
                    andrei@utilsoftwaresolutions.ro
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="footer-copyright">
            <p>
              Website realizat in cadrul platformei © 2020
              producatoridinromania.ro. {marker.title}{" "}
            </p>
          </div>
        </footer>
      </Row>

      <a
        className="github-fork-ribbon left-bottom fixed"
        href="#"
        data-ribbon="Producător verificat"
        title="Producător verificat"
      >
        Producător verificat
      </a>
    </Container>
  );
}
